/*Header */
/*scroll header*/
const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

// Set scroll threshold based on device
const scrollThreshold = isiOS ? 250 : 100;

$(window).scroll(function () {
	if ($(document).scrollTop() > scrollThreshold) {
		$(".header_wrapper").addClass("scrolled");
	} else {
		$(".header_wrapper").removeClass("scrolled");
	}
});

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function (event) {
	var st = $(this).scrollTop();
	if (st > lastScrollTop && st != 0) {
		// downscroll code
		$(".header_wrapper").addClass("down");
	} else {
		// upscroll code
		$(".header_wrapper").removeClass("down");
		// $(".header_wrapper").css("transition-duration", "0.2s");
	}
	lastScrollTop = st;
});

/*Language Switcher*/
$(".language.is-active").on("click", function () {
	return false;
});

/*Open Menu*/
$(".menu_open button").on("click", function () {
	if ($(this).hasClass("is-active")) {
		$("body").css("overflow-y", "scroll");
		$(this).removeClass("is-active");
		$(".headermenu").removeClass("is-active");
		$(".header_wrapper").removeClass("openmenu");
		$(".menu li").removeClass("aos-animate");
	} else {
		$(this).addClass("is-active");
		jQuery("body").css("overflow-y", "hidden");
		$(".headermenu").addClass("is-active");
		$(".header_wrapper").addClass("openmenu");
		setTimeout(function () {
			$(".menu li").each(function (index) {
				$(this).addClass("aos-animate");
				$(this).addClass("aos-init");
			});
		}, 1000);
	}
});

/*Menu Animation*/
$(document).ready(function () {
	$(".menu > li").each(function (index) {
		$(this).attr("data-aos", "fade-up");
		$(this).attr("data-aos-duration", "600");
		$(this).addClass("aos-init");
		setTimeout(function () {
			$(".menu li").removeClass("aos-animate");
		}, 300);
		if (index == 0) {
			$(this).attr("data-aos-delay", 100);
		} else {
			$(this).attr("data-aos-delay", index * 150);
		}
	});
});

/*Gallery*/
import Swiper, { Navigation } from "swiper";
const gallerySwiper = new Swiper(".gallery_swipe", {
	slidesPerView: 1,
	centeredSlides: false,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
});
// const imageSwiper = new Swiper(".image_swipe", {
// 	slidesPerView: 1,
// 	loop: true,
// 	centeredSlides: false,
// 	navigation: {
// 		nextEl: ".button-next",
// 		prevEl: ".button-prev",
// 	},
// });
const swiperContainers = document.querySelectorAll(".image_swipe");

swiperContainers.forEach((swiperContainer, index) => {
	// Only enable navigation if there’s more than one image
	const hasMultipleSlides = swiperContainer.querySelectorAll(".swiper-slide").length > 1;

	new Swiper(`.image_swipe_${index}`, {
		slidesPerView: 1,
		loop: hasMultipleSlides, // Enable loop only if there are multiple slides
		centeredSlides: false,
		navigation: hasMultipleSlides
			? {
					nextEl: `.button-next_${index}`, // Use the unique button classes
					prevEl: `.button-prev_${index}`,
			  }
			: false, // Disable navigation if only one image
	});
});

/*Slider Rooms Section Homepage*/
const propertySwiper = new Swiper(".property__swiper", {
	slidesPerView: 1,
	centeredSlides: true,
	breakpoints: {
		0: {
			centeredSlides: false,
		},
		768: {
			centeredSlides: false,
		},
		1025: {
			centeredSlides: false,
		},
	},
	navigation: {
		nextEl: ".button-next",
		prevEl: ".button-prev",
	},
});
/*Slider Rooms Section Homepage*/
const roomSwiper = new Swiper(".room_swiper", {
	slidesPerView: 1,
	centeredSlides: true,
	breakpoints: {
		0: {
			centeredSlides: false,
		},
		768: {
			centeredSlides: false,
		},
		1025: {
			centeredSlides: false,
		},
	},
	navigation: {
		nextEl: ".button-next",
		prevEl: ".button-prev",
	},
});

const subMenuBtns = document.querySelectorAll(".menu-item-has-children");
subMenuBtns.forEach((btn) => {
	btn.addEventListener("click", () => {
		btn.querySelector(".sub-menu").classList.toggle("is-active");
		btn.firstElementChild.classList.toggle("is-active");
		if (window.innerWidth < 768) {
			document.querySelector(".headermenu").classList.toggle("sub-open");
		}
	});
});

/*Header Logo*/
const imageUrl = document.querySelector(".header_logo img");
const headerLogo = document.querySelector(".header_wrapper");
const openMenu = document.querySelector(".menu_open");
const newUrl =
	window.location.origin +
	"/wp-content/themes/casa-vindemia/assets/images/Casa/white-logo.png";
const originalUrl = imageUrl.src;

// Add click event listener
openMenu.addEventListener("click", changeImage);

function changeImage() {
	if (headerLogo.classList.contains("openmenu")) {
		imageUrl.src = newUrl;
	} else {
		imageUrl.src = originalUrl;
	}
}
