const mapStyle = [
	{
		featureType: "administrative",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#444444",
			},
		],
	},
	{
		featureType: "administrative.country",
		elementType: "geometry",
		stylers: [
			{
				visibility: "on",
			},
		],
	},
	{
		featureType: "administrative.country",
		elementType: "labels.text",
		stylers: [
			{
				weight: "0.01",
			},
			{
				saturation: "100",
			},
		],
	},
	{
		featureType: "landscape",
		elementType: "all",
		stylers: [
			{
				color: "#f2eeeb",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "all",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "road",
		elementType: "all",
		stylers: [
			{
				saturation: -100,
			},
			{
				lightness: 45,
			},
		],
	},
	{
		featureType: "road",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#373737",
			},
		],
	},
	{
		featureType: "road.highway",
		elementType: "all",
		stylers: [
			{
				visibility: "simplified",
			},
		],
	},
	{
		featureType: "road.arterial",
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "transit",
		elementType: "all",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "water",
		elementType: "all",
		stylers: [
			{
				color: "#81b8d3",
			},
			{
				visibility: "on",
			},
		],
	},
];

/*id of map here*/
if ($("#map").length > 0) {
	window.addEventListener("load", initializeMap);
}

async function initializeMap() {
	const mapCanvas = document.getElementById("map");
	const coordsDynamic = mapCanvas.getAttribute("data-coords");
	const splittedCoords = coordsDynamic.split(",");
	const myLatLng = new google.maps.LatLng(splittedCoords[0], splittedCoords[1]);

	const mapOptions = {
		zoom: 15,
		gestureHandling: "cooperative",
		center: myLatLng,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		styles: mapStyle,
		scrollwheel: false,
	};

	const map = new google.maps.Map(mapCanvas, mapOptions);
	const iconSource =
		window.location.origin +
		"/wp-content/themes/casa-vindemia/assets/images/Casa/marker.png";

	// As of February 21st, 2024, google.maps.Marker is deprecated.
	// Please use google.maps.marker.AdvancedMarkerElement instead.
	// At this time, google.maps.Marker is not scheduled to be discontinued,
	// but google.maps.marker.AdvancedMarkerElement is recommended over google.maps.Marker.

	const marker = new google.maps.Marker({
		map: map,
		position: myLatLng,
		icon: {
			url: iconSource,
		},
	});

	// While google.maps.Marker will continue to receive bug fixes for any major regressions,
	// existing bugs in google.maps.Marker will not be addressed.
	// At least 12 months notice will be given before support is discontinued.

	marker.setAnimation(google.maps.Animation.BOUNCE);
	setTimeout(() => {
		marker.setAnimation(null);
	}, 1000);
}
